// src/hooks/queries/useGetUnitTypesQuery.js
import { useQuery } from "@tanstack/react-query";
import { getUnitTypes } from "@/services/getUnitTypes";

export const useGetUnitTypesQuery = () => {
	const propertyPublicId = process.env.NEXT_PUBLIC_PROPERTY_PUBLIC_ID;

	return useQuery({
		queryKey: ["unitTypes", propertyPublicId],
		queryFn: () => getUnitTypes({ propertyPublicId }),
		enabled: !!propertyPublicId,
		staleTime: 60 * 60 * 1000, // 60 minutes in milliseconds
		cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
	});
};
